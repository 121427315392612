import { render, staticRenderFns } from "./AccountCard.vue?vue&type=template&id=ed8c344a"
import script from "./AccountCard.vue?vue&type=script&lang=js"
export * from "./AccountCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <b-card
    v-if="transactions"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Lastest Transactions</b-card-title>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item :to="{ name: 'account-transaction-history', params: { id: accountId } }">
          History
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <div
        v-for="transaction in transactions"
        :key="transaction.id"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="`light-${resolveTransactionTypeVariantAndIcon(transaction.transactionTypeId).variant}`"
            >
              <feather-icon :icon="resolveTransactionTypeVariantAndIcon(transaction.transactionTypeId).icon" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="w-100">
            <h6>
              {{ transaction.description }}
              <small class="text-muted"> (#{{ transaction.id }})</small>
            </h6>
            <small> {{ formatDateDdMmYyyy(transaction.transactionDateUtc) }}</small>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder">
          <p
            class="text-nowrap mb-50 text-right"
            :class="(transaction.amount < 0) ? 'text-danger' : 'text-success'"
          >
            {{ formatCurrency(transaction.amount) }}
          </p>
          <small class="text-muted text-right text-nowrap">
            {{ formatCurrency(transaction.newBalance) }}
          </small>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BDropdown, BDropdownItem, BMedia, BMediaAside, BMediaBody, BRow } from 'bootstrap-vue';
import { computed } from "@vue/composition-api";
import store from '@/store';
import useAccountTransaction from '@/modules/account/composables/useAccountTransaction';
import router from '@/router';
import useUtils from '@/composables/useUtils';

export default {
  setup() {
    const { formatCurrency, formatDateDdMmYyyy } = useUtils();
    const { fetchAccountTransactions, resolveTransactionTypeVariantAndIcon } = useAccountTransaction();

    const accountId = router.currentRoute.params.id;
    const params = { pageSize: 7, pageNumber: 1 };

    fetchAccountTransactions(accountId, params);

    const transactions = computed(() => store.getters['accountTransaction/lastTransactions']);

    return {
      // Const
      accountId,

      // Computed
      transactions,

      // Methods
      formatCurrency,
      formatDateDdMmYyyy,
      resolveTransactionTypeVariantAndIcon,
    };
  },
  components: {
    BAvatar,
    BBadge,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BDropdown,
    BDropdownItem,
    BMedia,
    BMediaAside,
    BMediaBody,
    BRow
  }
};
</script>
<template>
  <b-modal
    ref="refModal"
    id="account-statement-modal"
    title="Account Statement"
    no-close-on-backdrop
  >
    <validation-observer ref="accountStatementForm">
      <b-form id="account-statement">
        <validation-provider
          #default="{ errors }"
          name="Date From"
          rules="required"
        >
          <b-form-group
            label="Date From"
            label-for="date-to"
          >
            <flat-pickr
              v-model="dateFrom"
              class="form-control"
              :config="{ dateFormat: 'd/m/Y', enableTime: false }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Date To"
          rules="required"
        >
          <b-form-group
            label="Date To"
            label-for="date-to"
          >
            <flat-pickr
              v-model="dateTo"
              class="form-control"
              :config="{ dateFormat: 'd/m/Y', enableTime: false }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Transactions Status"
          rules="required"
        >
          <b-form-group
            label="Transaction Status"
            label-for="transaction-status"
          >
            <v-select
              v-model="transactionStatusIds"
              :options="transactionStatusList"
              label="name"
              :reduce="(transaction) => transaction.id"
              input-id="transaction-status"
              multiple
            >
              <template #option="data">
                <div
                  :class="`bg-${resolveTransactionStatusVariantAndIcon(data)}`"
                  class="rounded-circle d-inline-block mr-50"
                  style="height: 10px; width: 10px"
                />
                <span> {{ data.name }}</span>
              </template>

              <template #selected-option="data">
                <div
                  :class="`bg-${resolveTransactionStatusVariantAndIcon(data)}`"
                  class="rounded-circle d-inline-block mr-50"
                  style="height: 10px; width: 10px"
                />
                <span> {{ data.name }}</span>
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="outline-secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>

      <b-button
        variant="outline-primary"
        @click="validateFormToDownload"
      >
        Download
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormFile } from 'bootstrap-vue';
import { ref } from "@vue/composition-api";
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import router from '@/router';
import useAccount from '@/modules/account/composables/useAccount';
import useAccountTransaction from '@/modules/account/composables/useAccountTransaction';
import useUtils from '@/composables/useUtils';
import vSelect from 'vue-select';

export default {
  setup() {
    const { convertDateToUTC } = useUtils();
    const { downloadAccountStatement } = useAccount();
    const {
      resolveTransactionStatusVariantAndIcon,
      fetchTransactionStatus,
    } = useAccountTransaction();

    const dateFrom = ref(new Date());
    const dateTo = ref(new Date());
    const transactionStatusIds = ref([]);
    const transactionStatusList = ref([]);
    const refModal = ref(null);
    const accountStatementForm = ref(null);

    const initData = async () => {
      const transactionStatus = await fetchTransactionStatus();
      transactionStatusList.value = transactionStatus;
    }

    const validateFormToDownload = async (modalEvt) => {
      modalEvt.preventDefault();

      const valid = await accountStatementForm.value.validate();
        if (!valid) return;

        const params = {
          dateFrom: convertDateToUTC(dateFrom.value),
          dateTo: convertDateToUTC(dateTo.value),
          transactionStatusIds: transactionStatusIds.value
        };
        const response = await downloadAccountStatement(router.currentRoute.params.id, params);

        if (response !== null) {
          // create file link in browser's memory
          const url = URL.createObjectURL(response.data);

          // create "a" HTLM element with href to file & click
          const link = document.createElement('a');
          link.href = url;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          refModal.value.hide();
        }
    };

    initData();
    return {
      // Reactive
      dateFrom,
      dateTo,
      transactionStatusIds,
      refModal,
      transactionStatusIds,
      transactionStatusList,
      accountStatementForm,

      // Method
      resolveTransactionStatusVariantAndIcon,
      validateFormToDownload,

      // Validator
      required
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormFile,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#account-statement {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="accountData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching account data
      </h4>
      <div class="alert-body">
        No account found with this account id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'account-list' }"
        >
          Account List
        </b-link>
        for other accounts.
      </div>
    </b-alert>

    <template v-if="accountData">
      <b-row>
        <b-col cols="12">
          <account-card :account-data="accountData" />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col md="6">
          <account-transaction-card />
        </b-col>
        <b-col md="6">
          <account-transaction-payout-card />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import { BAlert, BCol, BLink, BRow } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import AccountCard from '@/modules/account/components/AccountCard.vue';
import AccountTransactionCard from '@/modules/account/components/AccountTransactionCard.vue';
import AccountTransactionPayoutCard from '@/modules/account/components/AccountTransactionPayoutCard.vue';
import router from '@/router';
import useAccount from '@/modules/account/composables/useAccount';

export default {
  setup() {
    const { getAccount } = useAccount();

    const accountData = ref(null);

    getAccount(router.currentRoute.params.id)
      .then(account => { accountData.value = account; })
      .catch(_ => { accountData.value = undefined; });

    return {
      // Reactive
      accountData,
    };
  },
  components: {
    BAlert,
    BCol,
    BLink,
    BRow,
    AccountCard,
    AccountTransactionCard,
    AccountTransactionPayoutCard,
  }
};
</script>

import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useAccount = () => {
  const toast = useToast();

  const createAccount = async (account) => {
    try {
      return await store.dispatch('account/create', account);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const updateAccount = async (account) => {
    try {
      return await store.dispatch('account/update', account);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const getAccount = async (accountId) => {
    const account = await store.dispatch('account/get', accountId);
    return account;
  };

  const getAccountList = async (params) => {
    try {
      return await store.dispatch('account/getList', params);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the list of accounts',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const downloadAccountStatement = async (accountId, params) => {
    try {
      return await store.dispatch('account/downloadAccountStatement', {
        accountId,
        params,
      });
    } catch (error) {
      const title =
        error.response.status === 404
          ? 'No transaction found in date range'
          : 'Error downloading the account statement';
      toast.error({
        component: ToastificationContent,
        props: {
          title,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  return {
    //Method
    getAccount,
    getAccountList,
    createAccount,
    updateAccount,
    downloadAccountStatement,
  };
};

export default useAccount;
